import * as axios from 'axios';

export const API = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
});

const onRejected = error => {
    return Promise.reject(error);
};

API.interceptors.request.use(
    config => config,
    onRejected
);

API.interceptors.response.use(
    ({ data }) => data,
    onRejected
);