/**
 * Return message from validation error
 *
 * @param {any[]} errors
 * @param {string} prop
 * @returns {string|undefined}
 */
export const getValidationErrorMessage = (errors, prop) => {
    const props = prop.split('.');
    const err = props.reduce((errors, value) => value === props[props.length - 1] ? errors : errors.find(({ property }) => property === value)?.children || [], errors).find(({ property }) => property === props[props.length - 1]);
    return err?.constraints[Object.keys(err?.constraints)[0]];
};