import {
    Box,
    Container,
    Stack,
    Typography
} from '@mui/material';
import { Collapse } from './../components/common';

export default function FrequentlyAskedQuestions() {
    return (
        <Container maxWidth="xl">
            <Box
                marginTop={{
                    xs: 15,
                    md: 20,
                    lg: 25
                }}
            >
                <Typography
                    variant="h1"
                    textAlign="center"
                    marginBottom={{
                        xs: 4,
                        md: 8,
                        lg: 10
                    }}
                    fontSize={{
                        xs: 24,
                        sm: 34,
                        md: 48
                    }}
                >
                    Түгээмэл асуултууд
                </Typography>
                <Stack
                    spacing={1}
                    marginY={2}
                >
                    <Collapse
                        summary="O'cademy гэж юу вэ?"
                        details="O'cademy нь байгууллагын сургалт, хөгжлийн удирдлагын систем юм. Байгууллагын дотоод сургалтууд болон бусад сургалтууд багтана."
                    />
                    <Collapse
                        summary="Хэрхэн бүртгүүлэх вэ?"
                        details='Нүүр хуудсанд байрлах "Хүсэлт илгээх" товчийг дарж гарч ирэх асуулгыг бөглөж илгээсний дараа бид тантай эргэн холбогдох болно.'
                    />
                    <Collapse
                        summary="Хэрхэн багш болох вэ?"
                        details="Багш болоход бидэн рүү холбогдож өөрийн сургалтын талаар товч мэдээллийг илгээнэ. Түүний дараа хамтран ажиллах гэрээ байгуулсанаар өөрийн цахим сургалтыг системд оруулах эрхтэй болно."
                    />
                    <Collapse
                        summary="Хувь хүн бүртгүүлж сургалт үзэх боломжтой юу?"
                        details="Одоогоор хувь хүн бүртгүүлж сургалт үзэх боломжгүй."
                    />
                </Stack>
            </Box>
        </Container>
    );
}