import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import {
    BrowserRouter,
    Route,
    Routes
} from 'react-router-dom';
import { App } from './layouts';
import {
    ContactUs,
    FrequentlyAskedQuestions,
    Home,
    NotFound,
    PrivacyPolicy
} from './pages';
import { theme } from './theme/muiTheme';

export default () => (
    <SnackbarProvider
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
    >
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <App />
                        }
                    >
                        <Route
                            index
                            element={
                                <Home />
                            }
                        />
                        <Route
                            path="frequently-asked-questions"
                            element={
                                <FrequentlyAskedQuestions />
                            }
                        />
                        <Route
                            path="contact-us"
                            element={
                                <ContactUs />
                            }
                        />
                        <Route
                            path="privacy-policy"
                            element={
                                <PrivacyPolicy />
                            }
                        />
                    </Route>
                    <Route
                        path="*"
                        element={
                            <NotFound />
                        }
                    />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    </SnackbarProvider>
)
