import {
    Box,
    Container,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import {
    Mail,
    MapPin,
    Phone
} from 'react-feather';

export default function ContactUs() {
    return (
        <Container maxWidth="xl">
            <Box
                marginTop={{
                    xs: 15,
                    md: 20,
                    lg: 25
                }}
            >
                <Typography
                    variant="h1"
                    textAlign="center"
                    marginBottom={{
                        xs: 4,
                        md: 8,
                        lg: 10
                    }}
                    fontSize={{
                        xs: 24,
                        sm: 34,
                        md: 48
                    }}
                >
                    Бидэнтэй холбогдох
                </Typography>
                <Box marginY={2}>
                    <Box
                        sx={{
                            backgroundColor: '#161a2d',
                            borderRadius: 2,
                            overflow: 'hidden'
                        }}
                    >
                        <div class="gmap">
                            <iframe
                                src="https://maps.google.com/maps?q=Park%20Place&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                width="100%"
                                height="300"
                                scrolling="no"
                                frameborder="0"
                                style={{
                                    borderRadius: '16px 16px 0 0'
                                }}
                            ></iframe>
                        </div>
                        <Box
                            padding={{
                                xs: '16px 24px',
                                sm: '24px 32px'
                            }}
                        >
                            <Grid
                                spacing={2}
                                container
                            >
                                <Grid
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    item
                                >
                                    <List disablePadding>
                                        <ListItem
                                            alignItems="flex-start"
                                            disablePadding
                                        >
                                            <ListItemIcon marginTop="6px">
                                                <Phone color="#e90041" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Утасны дугаар"
                                                secondary="7735-2222"
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    item
                                >
                                    <List disablePadding>
                                        <ListItem
                                            alignItems="flex-start"
                                            disablePadding
                                        >
                                            <ListItemIcon marginTop="6px">
                                                <Mail color="#e90041" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="И-мэйл хаяг"
                                                secondary="hello@metakeys.mn"
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    item
                                >
                                    <List disablePadding>
                                        <ListItem
                                            alignItems="flex-start"
                                            disablePadding
                                        >
                                            <ListItemIcon marginTop="6px">
                                                <MapPin color="#e90041" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Хаяг"
                                                secondary="Park Place, 4th floor, #404, Sukhbaatar district, 1st khoroo, Ulaanbaatar, Mongolia"
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}