import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    CardMedia,
    Chip,
    Container,
    Grid,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import {
    Book,
    CheckCircle,
    Lock,
    Play,
    PlayCircle,
    Settings
} from 'react-feather';
import { scroller } from 'react-scroll';
import Slider from 'react-slick';
import { TabPanel } from './../components/common';
import { useWidth } from './../hooks';
import { getValidationErrorMessage } from './../lib/helpers';
import { API } from './../plugins/axios';

const CustomTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        display: 'none'
    }
});

const CustomTab = styled(Tab)({
    alignItems: 'flex-start',
    maxWidth: 'none',
    padding: '16px 24px',
    textAlign: 'left',
    borderRadius: 8,
    '&.Mui-selected': {
        color: '#fff',
        backgroundColor: '#1d1e2c'
    }
});

export default function Home() {
    const width = useWidth();

    const { enqueueSnackbar } = useSnackbar();

    const [
        tab,
        setTab
    ] = useState(0);
    const [
        mutating,
        mutate
    ] = useState(false);
    const [
        errors,
        setErrors
    ] = useState([]);
    const [
        email,
        setEmail
    ] = useState('');
    const [
        phone,
        setPhone
    ] = useState('');
    const [
        companyName,
        setCompanyName
    ] = useState('');
    const [
        employeeCount,
        setEmployeeCount
    ] = useState('');

    const scrollTo = target => {
        scroller.scrollTo(target, {
            smooth: 'easeInOutQuart',
            duration: 1600
        });
    };

    const slidesToShow = () => {
        switch (width) {
            case 'xs':
                return 3;
            case 'sm':
                return 4;
            case 'md':
                return 5;
            case 'lg':
                return 7;
            case 'xl':
                return 8;
        }
    };

    const contactUs = async () => {
        try {
            mutate(true);
            await API.post('/contact-us', {
                email,
                phone,
                companyName,
                employeeCount
            }).then(() => {
                setEmail('');
                setPhone('');
                setCompanyName('');
                setEmployeeCount('');
            });
            mutate(false);
            setErrors([]);
            enqueueSnackbar('Баярлалаа. Таны хүсэлтийг хүлээн авлаа.', {
                autoHideDuration: 10000,
                variant: 'success'
            });
        } catch (err) {
            if (err?.response?.status === 400) {
                mutate(false);
                setErrors(err.response.data.message);
            }
        }
    };

    return (
        <div>
            <Container maxWidth="xl">
                <Box
                    marginTop={{
                        xs: 20,
                        sm: 25,
                        md: 35,
                        lg: 25,
                        xl: 35
                    }}
                >
                    <Grid
                        justifyContent="center"
                        container
                    >
                        <Grid
                            xs={12}
                            sm={10}
                            md={8}
                            lg={7}
                            xl={5}
                            item
                        >
                            <Typography
                                variant="h1"
                                textAlign="center"
                                fontSize={{
                                    xs: 40,
                                    sm: 48,
                                    md: 60
                                }}
                                sx={{
                                    margin: {
                                        xs: '0 -16px',
                                        sm: 0
                                    }
                                }}
                            >
                                Ажилтанд зориулсан онлайн сургалтууд
                            </Typography>
                            <Typography
                                variant="h6"
                                marginTop={3}
                                color="text.secondary"
                                textAlign="center"
                                fontWeight={300}
                                fontSize={{
                                    xs: 16,
                                    sm: 18,
                                    md: 20
                                }}
                            >
                                Байгууллагын ажилтануудад зориулсан мэдлэгээ ахиулах сургалтуудыг танд санал болгоно.
                            </Typography>
                            <Box
                                marginTop={8}
                                textAlign="center"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => scrollTo('request-demo')}
                                    sx={{
                                        padding: '8px 40px'
                                    }}
                                >
                                    Хүсэлт илгээх
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Box
                marginY={{
                    xs: 4,
                    sm: 12
                }}
            >
                <Slider
                    arrows={false}
                    autoplay={true}
                    autoplaySpeed={0}
                    cssEase="linear"
                    dots={false}
                    infinite={true}
                    pauseOnHover={false}
                    slidesToShow={slidesToShow()}
                    slidesToScroll={1}
                    speed={9000}
                    swipe={false}
                    touchMove={false}
                >
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box marginTop={0}>
                            <CardMedia
                                image="/static/images/1.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box
                            marginTop={{
                                xs: 8,
                                sm: 12,
                                lg: 16
                            }}
                        >
                            <CardMedia
                                image="/static/images/2.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box
                            marginTop={{
                                xs: 4,
                                sm: 6,
                                lg: 8
                            }}
                        >
                            <CardMedia
                                image="/static/images/3.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box
                            marginTop={{
                                xs: 8,
                                sm: 12,
                                lg: 16
                            }}
                        >
                            <CardMedia
                                image="/static/images/4.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box
                            marginTop={{
                                xs: 12,
                                sm: 18,
                                lg: 24
                            }}
                        >
                            <CardMedia
                                image="/static/images/5.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box
                            marginTop={{
                                xs: 4,
                                sm: 6,
                                lg: 8
                            }}
                        >
                            <CardMedia
                                image="/static/images/6.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box
                            marginTop={{
                                xs: 12,
                                sm: 18,
                                lg: 24
                            }}
                        >
                            <CardMedia
                                image="/static/images/7.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box
                            marginTop={{
                                xs: 8,
                                sm: 12,
                                lg: 16
                            }}
                        >
                            <CardMedia
                                image="/static/images/8.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box marginTop={0}>
                            <CardMedia
                                image="/static/images/1.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box
                            marginTop={{
                                xs: 8,
                                sm: 12,
                                lg: 16
                            }}
                        >
                            <CardMedia
                                image="/static/images/2.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box
                            marginTop={{
                                xs: 4,
                                sm: 6,
                                lg: 8
                            }}
                        >
                            <CardMedia
                                image="/static/images/3.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box
                            marginTop={{
                                xs: 8,
                                sm: 12,
                                lg: 16
                            }}
                        >
                            <CardMedia
                                image="/static/images/4.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box
                            marginTop={{
                                xs: 12,
                                sm: 18,
                                lg: 24
                            }}
                        >
                            <CardMedia
                                image="/static/images/5.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box
                            marginTop={{
                                xs: 4,
                                sm: 6,
                                lg: 8
                            }}
                        >
                            <CardMedia
                                image="/static/images/6.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box
                            marginTop={{
                                xs: 12,
                                sm: 18,
                                lg: 24
                            }}
                        >
                            <CardMedia
                                image="/static/images/7.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        paddingX={{
                            xs: 1,
                            xl: 2
                        }}
                    >
                        <Box
                            marginTop={{
                                xs: 8,
                                sm: 12,
                                lg: 16
                            }}
                        >
                            <CardMedia
                                image="/static/images/8.png"
                                sx={{
                                    width: '100%',
                                    paddingTop: '177%',
                                    borderRadius: 1
                                }}
                            />
                        </Box>
                    </Box>
                </Slider>
            </Box>
            <Container maxWidth="xl">
                <Box marginY={15}>
                    <Grid
                        alignItems="center"
                        rowSpacing={{
                            xs: 8,
                            md: 0
                        }}
                        columnSpacing={{
                            xs: 4,
                            xl: 25
                        }}
                        container
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            md={7}
                            lg={6}
                            item
                        >
                            <Typography
                                variant="h4"
                                textAlign={{
                                    xs: 'center',
                                    sm: 'left'
                                }}
                                fontSize={{
                                    xs: 24,
                                    md: 34
                                }}
                            >
                                Мэргэжилтнүүдээс суралцах чанартай контентуудыг O'cademy хүргэнэ
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            md={5}
                            lg={6}
                            item
                        >
                            <Grid
                                columnSpacing={{
                                    xs: 2,
                                    lg: 10
                                }}
                                rowSpacing={{
                                    xs: 6,
                                    sm: 4,
                                    md: 0
                                }}
                                container
                            >
                                <Grid
                                    xs={6}
                                    lg={4}
                                    item
                                >
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <Play color="#e90041" />
                                        <Typography variant="h5">
                                            150+
                                        </Typography>
                                    </Stack>
                                    <Box marginLeft={4}>
                                        <Typography
                                            variant="body1"
                                            color="text.secondary"
                                        >
                                            Видео сургалт
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    xs={6}
                                    lg={4}
                                    item
                                >
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <CheckCircle color="#00b3e9" />
                                        <Typography variant="h5">
                                            21+
                                        </Typography>
                                    </Stack>
                                    <Box marginLeft={4}>
                                        <Typography
                                            variant="body1"
                                            color="text.secondary"
                                        >
                                            Шалгалт
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    xs={6}
                                    lg={4}
                                    item
                                >
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <Book color="#e9bc00" />
                                        <Typography variant="h5">
                                            45+
                                        </Typography>
                                    </Stack>
                                    <Box marginLeft={4}>
                                        <Typography
                                            variant="body1"
                                            color="text.secondary"
                                        >
                                            Унших ном
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box marginTop={{
                                xs: 6,
                                md: 5
                            }}>
                                <Typography
                                    variant="h6"
                                    color="text.secondary"
                                    textAlign="center"
                                    fontWeight={300}
                                    fontSize={{
                                        xs: 16,
                                        sm: 18,
                                        md: 20
                                    }}
                                >
                                    Байгууллагадаа шаардлагатай байгаа сургалтуудыг цаг алдалгүй ажилтанууддаа хүргэх боломж
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box marginY={15}>
                    <Grid
                        columnSpacing={{
                            xs: 0,
                            sm: 8
                        }}
                        rowSpacing={{
                            xs: 2,
                            sm: 0
                        }}
                        alignItems="center"
                        container
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            md={7}
                            lg={8}
                            item
                        >
                            <TabPanel
                                value={0}
                                tab={tab}
                            >
                                <Box
                                    component="img"
                                    src="/static/images/screen.webp"
                                    sx={{
                                        display: 'block',
                                        width: '100%',
                                        borderRadius: 2
                                    }}
                                />
                            </TabPanel>
                            <TabPanel
                                value={1}
                                tab={tab}
                            >
                                <Box
                                    component="img"
                                    src="/static/images/screen.webp"
                                    sx={{
                                        display: 'block',
                                        width: '100%',
                                        borderRadius: 2
                                    }}
                                />
                            </TabPanel>
                            <TabPanel
                                value={2}
                                tab={tab}
                            >
                                <Box
                                    component="img"
                                    src="/static/images/screen.webp"
                                    sx={{
                                        display: 'block',
                                        width: '100%',
                                        borderRadius: 2
                                    }}
                                />
                            </TabPanel>
                            <TabPanel
                                value={3}
                                tab={tab}
                            >
                                <Box
                                    component="img"
                                    src="/static/images/screen.webp"
                                    sx={{
                                        display: 'block',
                                        width: '100%',
                                        borderRadius: 2
                                    }}
                                />
                            </TabPanel>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            md={5}
                            lg={4}
                            item
                        >
                            <CustomTabs
                                value={tab}
                                onChange={(_, value) => setTab(value)}
                                variant="standard"
                                orientation="vertical"
                            >
                                <CustomTab
                                    sx={{
                                        width: {
                                            xs: 'calc(100vw - 32px)',
                                            sm: 'auto'
                                        }
                                    }}
                                    label={
                                        <span>
                                            <Typography
                                                variant="h6"
                                                color="text.primary"
                                                fontSize={{
                                                    xs: 16,
                                                    sm: 18
                                                }}
                                            >
                                                Сургалт авах
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color="text.secondary"
                                                fontSize={{
                                                    xs: 14,
                                                    sm: 16
                                                }}
                                            >
                                                Өөрт хэрэгтэй сургалтаа сонгон үзэх
                                            </Typography>
                                        </span>
                                    }
                                />
                                <CustomTab
                                    sx={{
                                        width: {
                                            xs: 'calc(100vw - 32px)',
                                            sm: 'auto'
                                        }
                                    }}
                                    label={
                                        <span>
                                            <Typography
                                                variant="h6"
                                                color="text.primary"
                                                fontSize={{
                                                    xs: 16,
                                                    sm: 18
                                                }}
                                            >
                                                Оноо цуглуулан өрсөлд
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color="text.secondary"
                                                fontSize={{
                                                    xs: 14,
                                                    sm: 16
                                                }}
                                            >
                                                Сургалтаа үзээд оноо цуглуулан бэлгийн эзэн болох
                                            </Typography>
                                        </span>
                                    }
                                />
                                <CustomTab
                                    sx={{
                                        width: {
                                            xs: 'calc(100vw - 32px)',
                                            sm: 'auto'
                                        }
                                    }}
                                    label={
                                        <span>
                                            <Typography
                                                variant="h6"
                                                color="text.primary"
                                                fontSize={{
                                                    xs: 16,
                                                    sm: 18
                                                }}
                                            >
                                                Сертификат авах
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color="text.secondary"
                                                fontSize={{
                                                    xs: 14,
                                                    sm: 16
                                                }}
                                            >
                                                Хаана ч үнэлэгдэх сертификат
                                            </Typography>
                                        </span>
                                    }
                                />
                                <CustomTab
                                    sx={{
                                        width: {
                                            xs: 'calc(100vw - 32px)',
                                            sm: 'auto'
                                        }
                                    }}
                                    label={
                                        <span>
                                            <Typography
                                                variant="h6"
                                                color="text.primary"
                                                fontSize={{
                                                    xs: 16,
                                                    sm: 18
                                                }}
                                            >
                                                Бүгдийг хянах
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color="text.secondary"
                                                fontSize={{
                                                    xs: 14,
                                                    sm: 16
                                                }}
                                            >
                                                Ажилтнуудынхаа явцыг хянаж, хэрэгцээт сургалтыг нь санал болгох
                                            </Typography>
                                        </span>
                                    }
                                />
                            </CustomTabs>
                        </Grid>
                    </Grid>
                </Box>
                <Box marginY={15}>
                    <Grid
                        columnSpacing={{
                            sm: 2,
                            md: 6,
                            lg: 20
                        }}
                        rowSpacing={{
                            xs: 6,
                            md: 0
                        }}
                        container
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            lg={4}
                            item
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                            >
                                <PlayCircle color="#e90041" />
                                <Typography
                                    variant="h5"
                                    fontSize={{
                                        xs: 20,
                                        sm: 24
                                    }}
                                >
                                    Чанартай контент
                                </Typography>
                            </Stack>
                            <Box marginLeft={5}>
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                    sx={{
                                        marginTop: 2
                                    }}
                                >
                                    O'cademy систем контентын сангаа үргэлж шинэчилж байна.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={4}
                            item
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                            >
                                <Lock color="#e90041" />
                                <Typography
                                    variant="h5"
                                    fontSize={{
                                        xs: 20,
                                        sm: 24
                                    }}
                                >
                                    Аюулгүй байдал
                                </Typography>
                            </Stack>
                            <Box marginLeft={5}>
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                    sx={{
                                        marginTop: 2
                                    }}
                                >
                                    Байгууллагын дотоод мэдээллийн аюулгүй байдлыг хадгална.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={4}
                            item
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                            >
                                <Settings color="#e90041" />
                                <Typography
                                    variant="h5"
                                    fontSize={{
                                        xs: 20,
                                        sm: 24
                                    }}
                                >
                                    Шинэчлэлт
                                </Typography>
                            </Stack>
                            <Box marginLeft={5}>
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                    sx={{
                                        marginTop: 2
                                    }}
                                >
                                    Системийн сайжруулалт тогтмол хийгдсээр байна.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box marginY={3}>
                    <Box
                        sx={{
                            borderRadius: 2,
                            background: 'linear-gradient(-45deg, #836ecb 0%, #3f4574 100%)'
                        }}
                    >
                        <Box
                            sx={{
                                padding: {
                                    xs: '32px',
                                    sm: '32px 40px',
                                    lg: '48px 64px'
                                }
                            }}
                        >
                            <Grid
                                columnSpacing={{
                                    xs: 0,
                                    sm: 2,
                                    md: 6
                                }}
                                rowSpacing={{
                                    xs: 4,
                                    sm: 0
                                }}
                                alignItems="center"
                                justifyContent="center"
                                container
                            >
                                <Grid
                                    xs={12}
                                    sm={9}
                                    lg={5}
                                    item
                                >
                                    <Box
                                        textAlign={{
                                            xs: 'left',
                                            sm: 'center',
                                            lg: 'left'
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src="/static/images/video-tutorials.png"
                                            sx={{
                                                display: 'inline-block',
                                                width: {
                                                    xs: 34,
                                                    sm: 48
                                                },
                                                height: 'auto',
                                                marginBottom: 1
                                            }}
                                        />
                                        <Typography
                                            variant="h4"
                                            fontSize={{
                                                xs: 24,
                                                md: 34
                                            }}
                                        >
                                            Хүссэн категорио сонгоод яг одоо хичээлээ эхэл
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            marginTop={1}
                                            color="rgba(255, 255, 255, 0.54)"
                                            fontWeight={400}
                                            fontSize={{
                                                xs: 16,
                                                md: 20
                                            }}
                                        >
                                            Олон төрлийн сургалтуудаас сонгон суралцах шинэ боломж...
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    xs={12}
                                    md={11}
                                    lg={7}
                                    item
                                >
                                    <Grid
                                        spacing={{
                                            xs: 1,
                                            md: 2
                                        }}
                                        justifyContent={{
                                            xs: 'flex-start',
                                            sm: 'center',
                                            lg: 'flex-end'
                                        }}
                                        container
                                    >
                                        <Grid item>
                                            <Chip
                                                sx={{
                                                    borderRadius: 6,
                                                    height: {
                                                        xs: 40,
                                                        md: 48
                                                    },
                                                    padding: {
                                                        xs: '0 8px',
                                                        md: '0 12px'
                                                    }
                                                }}
                                                label={
                                                    <Typography
                                                        variant="subtitle1"
                                                        fontSize={{
                                                            xs: 14,
                                                            md: 16
                                                        }}
                                                    >
                                                        Санхүүгийн удирдлага
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                sx={{
                                                    borderRadius: 6,
                                                    height: {
                                                        xs: 40,
                                                        md: 48
                                                    },
                                                    padding: {
                                                        xs: '0 8px',
                                                        md: '0 12px'
                                                    }
                                                }}
                                                label={
                                                    <Typography
                                                        variant="subtitle1"
                                                        fontSize={{
                                                            xs: 14,
                                                            md: 16
                                                        }}
                                                    >
                                                        Манлайлал
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                sx={{
                                                    borderRadius: 6,
                                                    height: {
                                                        xs: 40,
                                                        md: 48
                                                    },
                                                    padding: {
                                                        xs: '0 8px',
                                                        md: '0 12px'
                                                    }
                                                }}
                                                label={
                                                    <Typography
                                                        variant="subtitle1"
                                                        fontSize={{
                                                            xs: 14,
                                                            md: 16
                                                        }}
                                                    >
                                                        Төслийн удирдлага
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                sx={{
                                                    borderRadius: 6,
                                                    height: {
                                                        xs: 40,
                                                        md: 48
                                                    },
                                                    padding: {
                                                        xs: '0 8px',
                                                        md: '0 12px'
                                                    }
                                                }}
                                                label={
                                                    <Typography
                                                        variant="subtitle1"
                                                        fontSize={{
                                                            xs: 14,
                                                            md: 16
                                                        }}
                                                    >
                                                        Харилцааны ур чадвар
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                sx={{
                                                    borderRadius: 6,
                                                    height: {
                                                        xs: 40,
                                                        md: 48
                                                    },
                                                    padding: {
                                                        xs: '0 8px',
                                                        md: '0 12px'
                                                    }
                                                }}
                                                label={
                                                    <Typography
                                                        variant="subtitle1"
                                                        fontSize={{
                                                            xs: 14,
                                                            md: 16
                                                        }}
                                                    >
                                                        Багийн ажиллагаа
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                sx={{
                                                    borderRadius: 6,
                                                    height: {
                                                        xs: 40,
                                                        md: 48
                                                    },
                                                    padding: {
                                                        xs: '0 8px',
                                                        md: '0 12px'
                                                    }
                                                }}
                                                label={
                                                    <Typography
                                                        variant="subtitle1"
                                                        fontSize={{
                                                            xs: 14,
                                                            md: 16
                                                        }}
                                                    >
                                                        Технологи
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                sx={{
                                                    borderRadius: 6,
                                                    height: {
                                                        xs: 40,
                                                        md: 48
                                                    },
                                                    padding: {
                                                        xs: '0 8px',
                                                        md: '0 12px'
                                                    }
                                                }}
                                                label={
                                                    <Typography
                                                        variant="subtitle1"
                                                        fontSize={{
                                                            xs: 14,
                                                            md: 16
                                                        }}
                                                    >
                                                        Менежмент
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                sx={{
                                                    borderRadius: 6,
                                                    height: {
                                                        xs: 40,
                                                        md: 48
                                                    },
                                                    padding: {
                                                        xs: '0 8px',
                                                        md: '0 12px'
                                                    }
                                                }}
                                                label={
                                                    <Typography
                                                        variant="subtitle1"
                                                        fontSize={{
                                                            xs: 14,
                                                            md: 16
                                                        }}
                                                    >
                                                        Манлайлал
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                sx={{
                                                    borderRadius: 6,
                                                    height: {
                                                        xs: 40,
                                                        md: 48
                                                    },
                                                    padding: {
                                                        xs: '0 8px',
                                                        md: '0 12px'
                                                    }
                                                }}
                                                label={
                                                    <Typography
                                                        variant="subtitle1"
                                                        fontSize={{
                                                            xs: 14,
                                                            md: 16
                                                        }}
                                                    >
                                                        Санхүүгийн удирдлага
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <Box marginY={3}>
                    <Box
                        sx={{
                            borderRadius: 2,
                            background: 'linear-gradient(-45deg, #6d9bc2 0%, #304c81 100%)'
                        }}
                    >
                        <Box
                            sx={{
                                padding: {
                                    xs: '32px',
                                    sm: '32px 40px',
                                    lg: '48px 64px'
                                }
                            }}
                        >
                            <Grid
                                columnSpacing={{
                                    xs: 0,
                                    sm: 2,
                                    md: 6
                                }}
                                rowSpacing={{
                                    xs: 4,
                                    sm: 0
                                }}
                                alignItems="center"
                                justifyContent={{
                                    xs: 'flex-start',
                                    sm: 'center',
                                    lg: 'space-between'
                                }}
                                container
                            >
                                <Grid
                                    xs={12}
                                    sm={8}
                                    md={9}
                                    lg={5}
                                    item
                                >
                                    <Box
                                        textAlign={{
                                            xs: 'left',
                                            sm: 'center',
                                            lg: 'left'
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src="/static/images/video-chat.png"
                                            sx={{
                                                display: 'inline-block',
                                                width: {
                                                    xs: 34,
                                                    sm: 48
                                                },
                                                height: 'auto',
                                                marginBottom: 1
                                            }}
                                        />
                                        <Typography
                                            variant="h4"
                                            fontSize={{
                                                xs: 24,
                                                md: 34
                                            }}
                                        >
                                            Өөрийн сургалтаа нэмээд орлого олох боломж
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            marginTop={1}
                                            color="rgba(255, 255, 255, 0.54)"
                                            fontWeight={400}
                                            fontSize={{
                                                xs: 16,
                                                md: 20
                                            }}
                                        >
                                            Олон төрлийн сургалтуудаас сонгон суралцах шинэ боломж...
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    xs={12}
                                    sm={6}
                                    item
                                >
                                    <Box
                                        component="img"
                                        src="/static/images/emoji.png"
                                        sx={{
                                            display: 'block',
                                            width: '100%',
                                            height: 'auto'
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <Box marginY={3}>
                    <Box
                        sx={{
                            borderRadius: 2,
                            background: 'linear-gradient(-45deg, #75cdab 0%, #306b81 100%)'
                        }}
                    >
                        <Box
                            sx={{
                                padding: {
                                    xs: '32px',
                                    sm: '32px 40px',
                                    lg: '48px 64px'
                                }
                            }}
                        >
                            <Grid
                                columnSpacing={{
                                    xs: 0,
                                    sm: 2,
                                    md: 6
                                }}
                                rowSpacing={{
                                    xs: 4,
                                    sm: 0
                                }}
                                alignItems="center"
                                justifyContent={{
                                    xs: 'flex-start',
                                    sm: 'center',
                                    lg: 'space-between'
                                }}
                                container
                            >
                                <Grid
                                    xs={12}
                                    sm={7}
                                    lg={5}
                                    item
                                >
                                    <Box
                                        textAlign={{
                                            xs: 'left',
                                            sm: 'center',
                                            lg: 'left'
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src="/static/images/star.png"
                                            sx={{
                                                display: 'inline-block',
                                                height: 'auto',
                                                marginBottom: 1,
                                                width: {
                                                    xs: 34,
                                                    sm: 48
                                                }
                                            }}
                                        />
                                        <Typography
                                            variant="h4"
                                            fontSize={{
                                                xs: 24,
                                                md: 34
                                            }}
                                        >
                                            Сургалтаа үзээд оноогоо цуглуул
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            marginTop={1}
                                            color="rgba(255, 255, 255, 0.54)"
                                            fontWeight={400}
                                            fontSize={{
                                                xs: 16,
                                                md: 20
                                            }}
                                        >
                                            Оноогоороо өрсөлдөн олон төрлийн бэлэгний эзэн болоорой
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    xs={12}
                                    sm={8}
                                    md={7}
                                    lg={5}
                                    item
                                >
                                    <Box
                                        component="img"
                                        src="/static/images/points-flag.png"
                                        sx={{
                                            display: 'block',
                                            width: '100%',
                                            height: 'auto'
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <div id="request-demo">
                    <Box marginY={3}>
                        <Box
                            sx={{
                                borderRadius: 2,
                                backgroundColor: '#151829',
                                padding: {
                                    xs: '32px 16px 16px',
                                    sm: '56px 16px',
                                    lg: '120px 16px',
                                }
                            }}
                        >
                            <Grid
                                justifyContent="center"
                                container
                            >
                                <Grid
                                    xs={12}
                                    sm={10}
                                    md={7}
                                    lg={6}
                                    item
                                >
                                    <Box textAlign="center">
                                        <Box
                                            component={CheckCircle}
                                            color="#e9bc00"
                                            width={{
                                                xs: 48,
                                                sm: 60,
                                                lg: 80
                                            }}
                                            height={{
                                                xs: 48,
                                                sm: 60,
                                                lg: 80
                                            }}
                                        />
                                    </Box>
                                    <Typography
                                        variant="h3"
                                        marginTop={2}
                                        textAlign="center"
                                        fontSize={{
                                            xs: 24,
                                            sm: 34,
                                            lg: 48
                                        }}
                                    >
                                        Байгууллагадаа сургалтын системтэй болох уу?
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        color="text.secondary"
                                        textAlign="center"
                                        fontWeight={300}
                                        fontSize={{
                                            xs: 16,
                                            sm: 20
                                        }}
                                        marginTop={{
                                            xs: 2,
                                            sm: 4
                                        }}
                                    >
                                        Ажилтануудаа мэргэшүүлэх сургалтуудыг авахыг хүсвэл яг одоо бүртгүүлээрэй. Бид тантай эргэн холбогдох болно.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                justifyContent="center"
                                marginTop={8}
                                container
                            >
                                <Grid
                                    xs={12}
                                    sm={7}
                                    md={5}
                                    lg={4}
                                    item
                                >
                                    <form
                                        onSubmit={e => {
                                            e.preventDefault();
                                            contactUs();
                                        }}
                                    >
                                        <TextField
                                            value={companyName}
                                            onChange={e => setCompanyName(e.target.value)}
                                            error={errors.some(({ property }) => property === 'companyName')}
                                            helperText={getValidationErrorMessage(errors, 'companyName')}
                                            label="Байгууллагын нэр"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <TextField
                                            value={employeeCount}
                                            onChange={e => setEmployeeCount(e.target.value)}
                                            error={errors.some(({ property }) => property === 'employeeCount')}
                                            helperText={getValidationErrorMessage(errors, 'employeeCount')}
                                            label="Ажилтны тоо"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <TextField
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            error={errors.some(({ property }) => property === 'email')}
                                            helperText={getValidationErrorMessage(errors, 'email')}
                                            label="И-мэйл хаяг"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <TextField
                                            value={phone}
                                            onChange={e => setPhone(e.target.value)}
                                            error={errors.some(({ property }) => property === 'phone')}
                                            helperText={getValidationErrorMessage(errors, 'phone')}
                                            label="Утасны дугаар"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <Box marginTop={2}>
                                            <LoadingButton
                                                variant="contained"
                                                size="large"
                                                type="submit"
                                                loading={mutating}
                                                disableElevation
                                                fullWidth
                                            >
                                                Илгээх
                                            </LoadingButton>
                                        </Box>
                                    </form>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </div>
            </Container>
        </div>
    );
}