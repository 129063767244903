import { Box } from "@mui/material"

export default function TabPanel(props) {
    const {
        children,
        value,
        tab
    } = props

    return (
        <Box
            sx={{
                display: tab === value ? 'block' : 'none'
            }}
        >
            {children}
        </Box>
    )
}