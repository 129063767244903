import {
    Facebook,
    LinkedIn,
    Twitter
} from '@mui/icons-material';
import {
    Box,
    Container,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomIconButton = styled(IconButton)({
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
    color: '#8187a7'
});

export default function AppBottomBar() {
    return (
        <Container maxWidth="xl">
            <Box marginY={12}>
                <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                >
                    <CustomIconButton
                        size="large"
                        component="a"
                        href="https://facebook.com"
                        target="_blank"
                    >
                        <Facebook />
                    </CustomIconButton>
                    <CustomIconButton
                        size="large"
                        component="a"
                        href="https://twitter.com"
                        target="_blank"
                    >
                        <Twitter />
                    </CustomIconButton>
                    <CustomIconButton
                        size="large"
                        component="a"
                        href="https://linkedin.com"
                        target="_blank"
                    >
                        <LinkedIn />
                    </CustomIconButton>
                </Stack>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                    marginTop={4}
                >
                    O'cademy ХХК. Бүх эрх хуулиар хамгаалагдсан.
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                    display="grid"
                    marginTop={4}
                    component="a"
                    href="/privacy-policy"
                >
                    Нууцлалын бодлого
                </Typography>
            </Box>
        </Container>
    );
}