import {
    Box,
    Container, Typography
} from '@mui/material';

export default function PrivacyPolicy() {
    return (
        <Container maxWidth="xl">
            <Box
                marginTop={{
                    xs: 15,
                    md: 20,
                    lg: 25
                }}
            >
                <Typography
                    variant="h1"
                    textAlign="center"
                    marginBottom={{
                        xs: 4,
                        md: 8,
                        lg: 10
                    }}
                    fontSize={{
                        xs: 24,
                        sm: 34,
                        md: 48
                    }}
                >
                    Нууцлалын бодлого
                </Typography>
                <Typography variant="text">
                    Энэхүү Нууцлалын Бодлого нь "O'cademy" ХХК (цаашид "Компани" гэж нэрлэх) хэрэглэгчийн хувийн мэдээллийг хэрхэн бүртгэн авах, ашиглах, дамжуулах, хадгалахыг зохицуулсан баримт бичиг юм.
                    Компани нь app.ocademy.mn вэб болон мобайл аппликэйшн (хамтад нь "Үйлчилгээ" гэж нэрлэх)-д бүртгүүлсэн бүх хэрэглэгчдийн хувийн нууцлалыг энэхүү баримт бичигт зааснаар хадгалж, хамгаална.
                    Компани нь энэхүү нууцлалын бодлогод тусгаснаас өөрөөр болон Монгол улсын хуульд зааснаас бусад ямар ч тохиолдолд хэрэглэгчийн аливаа мэдээллийг гуравдагч этгээдэд дамжуулахгүй. Үйлчилгээг авч байгаа бол хэрэглэгчийн уг нууцлалын бодлогыг зөвшөөрсөн гэж үзнэ.
                </Typography>
                <Typography
                    fontWeight="bold"
                    variant="h6"
                    marginTop={4}
                >
                    НЭГ. БҮТЭЭГДЭХҮҮНИЙ ТУХАЙ
                </Typography>
                <Typography variant="text">
                    O'cademy платформоор дамжуулан сургалтын үйлчилгээг авна.
                </Typography>
                <Typography
                    fontWeight="bold"
                    variant="h6"
                    marginTop={4}
                >
                    ХОЁР. БҮРТГЭЛИЙН ҮЕД АВАХ МЭДЭЭЛЭЛ
                </Typography>
                <Typography variant="text">
                    Веб сайтын бүртгэлийг гэрээт байгууллагын ажилчид өгөх эрхтэй. Хувийн мэдээллийг үүсгэх, мэдээлэл боловсруулах, ашиглахдаа хувь хүний нууцыг хамгаалах тухай хууль болон холбогдох бусад хууль тогтоомжийг баримтална.
                </Typography>
                <ul>
                    <li>Имэйл хаяг</li>
                    <li>Утасны дугаар</li>
                    <li>Эцэг/эх/-н нэр, нэр, Овог</li>
                    <li>Регистрийн дугаар</li>
                </ul>
                <Typography
                    fontWeight="bold"
                    variant="h6"
                    marginTop={4}
                >
                    ГУРАВ. МЭДЭЭЛЭЛ АШИГЛАХ
                </Typography>
                <Typography variant="text">
                    Дэмжигчийн мэдээллийг дараах байдлаар ашиглаж болно. Үүнд:
                </Typography>
                <ul>
                    <li>Маргаан шийдвэрлэх, хураамж төлбөр авах, техникийн асуудлуудыг шийдэхэд</li>
                    <li>Хууль бус үйл ажиллагаанаас урьдчилан сэргийлэх, Үйлчилгээний нөхцөлийг мөрдүүлэхэд</li>
                    <li>Компанийн өмнөөс мессеж, имэйлээр маркетингийн болон сурталчилгааны санал явуулахад</li>
                    <li>Хэрэглэгчийн хэрэгцээ, санал хүсэлтийг хүлээн авах, илүү сайн үйлчилгээ үзүүлэхийн тулд Хэрэглэгчийн хувийн мэдээллийг бусдаас олж авсан мэдээлэлтэй холбох буюу нэгтгэхэд</li>
                </ul>
                <Typography
                    fontWeight="bold"
                    variant="h6"
                    marginTop={4}
                >
                    ДӨРӨВ. МЭДЭЭЛЭЛ БОЛОВСРУУЛАХ, ДАМЖУУЛАХ
                </Typography>
                <ul>
                    <li>Төрийн эрх бүхий байгууллагын шийдвэрийг биелүүлэх, хуулийн дагуу мөрдөн шалгалт хийхэд оролцох зэрэг зорилгоор шилжүүлэх.</li>
                    <li>Компани хууль, эрх зүйн үүргээ биелүүлэхийн тулд Хэрэглэгчийн мэдээллийг задлах, хуваалцах үүрэгтэй.</li>
                    <li>Компани нь хэрэглэгчээ таних, мөнгө угаах, терроризмыг санхүүжүүлэхийн эсрэг тэмцэх үйл ажиллагаанд хяналт тавихад дэмжин ажилладаг гуравдагч этгээдэд шилжүүлж болно.</li>
                </ul>
                <Typography
                    fontWeight="bold"
                    variant="h6"
                    marginTop={4}
                >
                    ТАВ. АЮУЛГҮЙ БАЙДАЛ
                </Typography>
                <ul>
                    <li>Бид хэрэглэгчийн хувийн мэдээллийн нууцлал, аюулгүй байдлыг хамгаалахын тулд Монгол Улсын хууль тогтоомжийг баримтлан ажилладаг.</li>
                    <li>Хэрэглэгчийн мэдээллийн аюулгүй байдлыг хангах зорилгоор Хэрэглэгчийн мэдээлэлд зөвшөөрөлгүй эсхүл санаандгүй халдах, устгах, өөрчлөх, болоклох, хуулбарлах, гуравдагч этгээдэд дамжуулах зэрэг хууль бус үйл ажиллагаанаас хамгаалах шаардлагатай, хууль ёсны, байгууллагын болон техникийн арга хэмжээнүүдийг авч ажиллана.</li>
                    <li>Хэрэглэгчийн хувийн мэдээлэлд хандаж буй холбогдох ажилтнууд нь хувийн мэдээллийг задруулахгүй байх үүрэгтэй.</li>
                </ul>
                <Typography
                    fontWeight="bold"
                    variant="h6"
                    marginTop={4}
                >
                    ЗУРГАА. МЭДЭЭЛЭЛД НЭВТРЭХ
                </Typography>
                <ul>
                    <li>Хэрэглэгч өөрийн хувийн мэдээллийг биднээс авах эрхтэй. Түүнчлэн хэрэглэгч компанитай хамтран ажиллаж буй гуравдагч этгээдээс үйлчилгээ авч буй тохиолдолд өөрийн мэдээллийг харах, нэвтрэх зөвшөөрлийг зөвхөн хэрэглэгч өгөх эрхтэй.</li>
                </ul>
                <Typography
                    fontWeight="bold"
                    variant="h6"
                    marginTop={4}
                >
                    ДОЛОО. ХУВИЙН МЭДЭЭЛЭЛ АШИГЛАХ, БОЛОВСРУУЛАХ ҮЙЛ АЖИЛЛАГААГ ДУУСГАВАР БОЛГОХ
                </Typography>
                <Typography variant="text">
                    Дараах нөхцөлүүдэд бид таны хувийн мэдээллийг боловсруулахыг зогсоох болно:
                </Typography>
                <ul>
                    <li>Төрийн эрх бүхий байгууллагын шийдвэрээр мэдээлэл боловсруулах, ашиглахыг хориглосон</li>
                    <li>Гэнэтийн болон давагдашгүй хүчин зүйлийн нөлөөллөөр мэдээллийг ашиглах боломжгүй нөхцөл үүссэн</li>
                    <li>O'cademy веб болон аппликейны үйлчилгээ эрхлэгч нь үйл ажиллагаагаа зогсоосон</li>
                </ul>
                <Typography
                    fontWeight="bold"
                    variant="h6"
                    marginTop={4}
                >
                    НАЙМ. ХЭРЭГЛЭГЧ МЭДЭЭЛЛЭЭ УДИРДАХ
                </Typography>
                <ul>
                    <li>Хэрэглэгч хувийн мэдээлэл болон нууц үгээ хэдийд ч өөрчлөх боломжтой</li>
                    <li>Хэрэглэгч хувийн мэдээллээ устгуулах эрхтэй</li>
                    <li>Хэрэв хэрэглэгч өөрийн бүртгэл, мэдээллээ устгахыг хүссэн тохиолдолд hello@metakeys.mn цахим шуудангийн хаягт хүсэлтээ илгээснээр бид ажлын 1-3 хоногт системээс хэрэглэгчийн бүртгэл, мэдээллийг устгах болно</li>
                </ul>
                <Typography
                    fontWeight="bold"
                    variant="h6"
                    marginTop={4}
                >
                    ЕС. ХОЛБОО БАРИХ
                </Typography>
                <Typography variant="text">
                    Хувийн мэдээлэл, түүний аюулгүй байдалтай холбоотой тодруулах зүйл байвал hello@metakeys.mn цахим хаяг болон (+976) 7735-2222 дугаарт утсаар харилцах, холбоо барих боломжтой.
                </Typography>
                <Typography
                    fontWeight="bold"
                    variant="h6"
                    marginTop={4}
                >
                    АРАВ. ХАРИУЦЛАГА
                </Typography>
                <ul>
                    <li>O'cademy веб эрхлэгч нь Хэрэглэгчийн хувийн мэдээллийг хууль бусаар задруулсан, боловсруулсан, дамжуулсан, ашигласан бол Монгол Улсын Эрүүгийн эсхүл Зөрчлийн тухай хуулийн дагуу хариуцлага хүлээнэ.</li>
                    <li>Хэрэглэгч хувийн мэдээллээ үнэн зөв оруулах үүргээ биелүүлээгүйтэй холбоотой хариуцлагыг өөрөө хүлээнэ.</li>
                </ul>
            </Box>
        </Container>
    );
}