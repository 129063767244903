import {
    Box,
    Button,
    Container,
    Divider,
    Drawer,
    IconButton,
    MenuItem,
    MenuList,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';
import React, { useState } from 'react';
import {
    Menu,
    X as Close
} from 'react-feather';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { AppBarLink } from './../app';
import { Spacer } from './../common';

const useStyles = createUseStyles({
    paper: {
        minWidth: '100vw'
    },
    logo: {
        display: 'inline-block',
        float: 'left',
        height: 30,
        width: 'auto'
    }
});

export default function AppBar() {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const {
        paper,
        logo
    } = useStyles();

    const [
        open,
        setOpen
    ] = useState(false);

    return (
        <Box
            display="flex"
            position="absolute"
            top={0}
            bottom="auto"
            left={0}
            right={0}
            alignItems="center"
            justifyContent="space-between"
            height={{
                xs: 72,
                md: 120
            }}
        >
            <Container
                maxWidth="xl"
                sx={{
                    display: 'flex'
                }}
            >
                <Box
                    sx={{
                        width: 160
                    }}
                >
                    <Link to="/">
                        <img
                            alt="ocademy logo"
                            src="/static/images/logo-white.png"
                            className={logo}
                        />
                    </Link>
                </Box>
                <Spacer />
                {isMobile
                    ?
                    <IconButton onClick={() => setOpen(true)}>
                        <Menu />
                    </IconButton>
                    :
                    <React.Fragment>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={4}
                        >
                            <AppBarLink to="/">
                                Нүүр
                            </AppBarLink>
                            <AppBarLink
                                to="/frequently-asked-questions"
                            >
                                Ашиглах заавар
                            </AppBarLink>
                            <AppBarLink
                                to="/contact-us"
                            >
                                Бидэнтэй холбогдох
                            </AppBarLink>
                        </Stack>
                        <Spacer />
                        <Box
                            sx={{
                                width: 160,
                                textAlign: 'right'
                            }}
                        >
                            <Button
                                component="a"
                                href="https://app.ocademy.mn"
                                variant="text"
                                color="inherit"
                                sx={{
                                    padding: '8px 40px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.06)'
                                }}
                            >
                                Нэвтрэх
                            </Button>
                        </Box>
                    </React.Fragment>
                }
            </Container>
            <Drawer
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
                classes={{
                    paper
                }}
            >
                <Box
                    padding={2}
                    display="flex"
                    justifyContent="right"
                >
                    <IconButton onClick={() => setOpen(false)}>
                        <Close />
                    </IconButton>
                </Box>
                <Divider />
                <MenuList>
                    <MenuItem
                        to="/"
                        component={Link}
                        onClick={() => setOpen(false)}
                    >
                        <Typography variant="subtitle1">
                            Нүүр
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        to="/frequently-asked-questions"
                        component={Link}
                        onClick={() => setOpen(false)}
                    >
                        <Typography variant="subtitle1">
                            Ашиглах заавар
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        to="/contact-us"
                        component={Link}
                        onClick={() => setOpen(false)}
                    >
                        <Typography variant="subtitle1">
                            Бидэнтэй холбогдох
                        </Typography>
                    </MenuItem>
                </MenuList>
                <Box padding={2}>
                    <Button
                        component="a"
                        href="https://app.ocademy.mn"
                        variant="text"
                        color="inherit"
                        fullWidth
                        sx={{
                            padding: '8px 40px',
                            backgroundColor: 'rgba(255, 255, 255, 0.06)'
                        }}
                    >
                        Нэвтрэх
                    </Button>
                </Box>
            </Drawer>
        </Box>
    );
}