import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    shape: {
        borderRadius: 8
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#ed254d'
        },
        text: {
            secondary: '#8187a7'
        },
        background: {
            default: '#101221',
            paper: '#151828'
        }
    },
    typography: {
        h1: {
            fontFamily: 'Nunito Sans',
            fontWeight: 700
        },
        h2: {
            fontFamily: 'Nunito Sans',
            fontWeight: 700
        },
        h3: {
            fontFamily: 'Nunito Sans',
            fontWeight: 700
        },
        h4: {
            fontFamily: 'Nunito Sans',
            fontWeight: 700
        },
        h5: {
            fontFamily: 'Nunito Sans',
            fontWeight: 700
        },
        h6: {
            fontFamily: 'Nunito Sans',
            fontWeight: 700
        },
        subtitle1: {
            fontFamily: 'Nunito Sans',
            fontWeight: 700
        },
        subtitle2: {
            fontFamily: 'Nunito Sans',
            fontWeight: 700
        },
        body1: {
            fontFamily: 'Nunito Sans',
            fontWeight: 400
        },
        body2: {
            fontFamily: 'Nunito Sans',
            fontWeight: 400
        },
        button: {
            fontFamily: 'Nunito Sans',
            fontWeight: 700,
            textTransform: 'none'
        },
        caption: {
            fontFamily: 'Nunito Sans',
            fontWeight: 400
        },
        overline: {
            fontFamily: 'Nunito Sans',
            fontWeight: 400
        }
    },
    components: {
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true
            }
        }
    }
});