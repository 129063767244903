import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function useWidth() {
    const theme = useTheme();
    const keys = [
        ...theme.breakpoints.keys
    ].reverse();

    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useMediaQuery(theme.breakpoints.up(key)) && !output
                ? key
                : output;
        }, null) || 'xs'
    );
}
