import { Outlet } from 'react-router-dom';
import {
    AppBar,
    AppBottomBar
} from './../components/app';

export default function App() {
    return (
        <div>
            <AppBar />
            <Outlet />
            <AppBottomBar />
        </div>
    );
}