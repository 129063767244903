import {
    Link,
    useMatch,
    useResolvedPath
} from 'react-router-dom';
import { Button } from '@mui/material';

export default function MenuLink(props) {
    const {
        children,
        ...other
    } = props;

    const resolved = useResolvedPath(props.to);
    const match = useMatch({
        path: resolved.pathname,
        end: true
    });

    return (
        <Button
            {...other}
            component={Link}
            color="inherit"
            variant="text"
            sx={{
                color: match ? '#fff' : '#8187a7'
            }}
        >
            {children}
        </Button>
    );
}
